import {
  Config as ProjectMetaConfig,
  ProjectMetaApiClient,
} from "~/lib/projectMetaApiClient";
import {
  Config as ClientContentCreatorConfig,
  ClientContentCreatorApiClient,
} from "~/lib/clientContentCreatorApiClient";
import type { AuthedUser } from "~/lib/user";

const config = useRuntimeConfig();

type Http = { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };

export async function createProjectMetaApiClient(
  http?: Http
): Promise<ProjectMetaApiClient> {
  let configuration = new ProjectMetaConfig();
  const nuxtApp = useNuxtApp();
  const user: AuthedUser | null = await nuxtApp.$user();

  configuration.getAuthorization = () =>
    user?.accessToken ? `Bearer ${user?.accessToken}` : "";

  return new ProjectMetaApiClient(
    configuration,
    config.public.projectMetaApiUrl,
    http
  );
}

export async function createClientContentCreatorApiClient(
  http?: Http
): Promise<ClientContentCreatorApiClient> {
  let configuration = new ClientContentCreatorConfig();
  const nuxtApp = useNuxtApp();
  const user: AuthedUser | null = await nuxtApp.$user();

  configuration.getAuthorization = () =>
    user?.accessToken ? `Bearer ${user?.accessToken}` : "";

  return new ClientContentCreatorApiClient(
    configuration,
    config.public.clientContentCreatorApiUrl,
    http
  );
}
